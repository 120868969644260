import React, { Fragment, useContext } from 'react'
import { Container , Row, Col } from 'reactstrap';
import { ReservationContext } from '../../context/reservation.context';

import 'moment/locale/cs.js';
import 'moment/locale/es.js';
import 'moment/locale/fr.js';
import 'moment/locale/nl.js';
import moment from 'moment';
import { ReservationPolicy } from './reservation-policy';

export default function Summary(props) {

    const { reservation } = useContext(ReservationContext);
    const { rate_stop_super_store } = reservation;
    const {lang, tripValidator, isOneWayAirportDestination, isRoundTrip, isServiceActive} = props;

    if(lang === 'eng'){
        moment.locale('en');
    }else{
        moment.locale('es');
    }
    // console.log(props)
    return (
        <Fragment>
            <Container className="summary-page">
                <Row className="my-3">
                    <Col xs={12} md={8}>
                        <h3>{props.title}</h3>
                    </Col>
                    <Col xs={12} md={4} className="text-right">
                        <h3 style={{color:'#000000',fontWeight:700}}>Total: ${props.totalPayment} USD</h3>
                    </Col>
                </Row>
                <Row className="data-information">
                    <Col xs={12} md={6}>
                        <h5 style={{color:'#000'}}>{ lang === 'eng' ? 'Customer information' : 'Información del cliente'}</h5>
                        <h6>{ lang === 'eng' ? 'Full name:' : 'Nombre:'} <span>{props.data.fullname}</span></h6>
                        <h6>{ lang === 'eng' ? 'Phone:' : 'Telfono:'} <span>{props.data.cellphone}</span></h6>
                        <h6>{ lang === 'eng' ? 'E-mail:' : 'Correo:'} <span>{props.data.email}</span></h6>
                        <h5 style={{color:'#000'}}>{ lang === 'eng' ? 'Transport Selected' : 'Unidad Seleccionada'} </h5> { lang === 'eng' ? '' : ''}
                        <h6>{ lang === 'eng' ? 'Vehicle:' : 'Vehiculo:'} <span>{props.unit.label}</span></h6>
                        <h6>{ lang === 'eng' ? 'Trip type:' : 'Tipo de Servicio'} <span>{props.data.trip_type}</span></h6>
                        <h6>{ lang === 'eng' ? 'Passenger number:' : 'Número de Pasajeros:'} <span>{props.data.total_passengers}</span></h6>
                        <h6>{ lang === 'eng' ? 'Pickup Location:' : 'Ubicación de Recogida'} <span>{props.data.pickup_location}</span></h6>
                        <h6>{ lang === 'eng' ? 'Destination:' : 'Destino:'} <span>{props.data.destination_location}</span></h6>
                    </Col>
                    <Col xs={12} md={6}>
                    {
                    !tripValidator && (<>
                        <h5 style={{color:'#000'}}>{ lang === 'eng' ? 'Arrival Information:' : 'Información de Arribo:'}</h5>
                        <h6>{ lang === 'eng' ? 'Date / Time:' : 'Fecha / Hora:'} <span>{ props.data.pickup_date } - {props.data.pickup_time}</span></h6>
                        <h6>{ lang === 'eng' ? 'Flight Number:' : 'Número de Vuelo:'} <span>{props.data.arrival_flight_number}</span></h6>
                        <h6>{ lang === 'eng' ? 'Airline Name:' : 'Aerolinea:'} <span>{props.data.arrival_airline}</span></h6>
                        <hr />
                    </>)}
                    {
                        (props.data.trip_type === 'Round Trip') || (isOneWayAirportDestination) ? <>
                        <Fragment>
                            <h5 style={{color:'#000'}}>{ lang === 'eng' ? 'Departure information:' : 'Información de Partida:'}</h5>
                            <h6>{ lang === 'eng' ? 'Date / Time:' : 'Fecha / Hora:'} <span>{ props.data.departure_date } - {props.data.departure_flight_time}</span></h6>
                            <h6>{ lang === 'eng' ? 'Flight Number:' : 'Número de Vuelo:'} <span>{props.data.departure_flight_number}</span></h6>
                            <h6>{ lang === 'eng' ? 'Airline Name: ' : 'Aerolinea:'}<span>{props.data.departure_airline}</span></h6>
                            <div style={{border:'1px solid black', borderRadius:'8px', padding:'.5rem'}}>
                                <h6>{ lang === 'eng' ? 'Scheduled Hotel Pickup Time must be 3 hours before:' : 'Recogida en hotel 3 horas antes:'} <span>{props.data.departure_pickup_time_hotel}</span></h6>
                            </div>
                        </Fragment>
                        </>: null
                    }
                    </Col>
                    {
                        props.isServiceActive && (
                            <Col xs={12}>
                                <h5 style={{color:'#000'}}>Extra Service:</h5>
                                <h6>Stop at the Supermarket or Grocery Store: ${rate_stop_super_store} USD</h6>
                            </Col>
                        )
                    }
                </Row>
            </Container>
            <hr />
            {
                <ReservationPolicy
                    isRoundTrip={isRoundTrip}
                    isServiceActive={isServiceActive}
                    isOneWayAirportDestination={isOneWayAirportDestination}
                />
            }
            
            
        </Fragment>
    )
}