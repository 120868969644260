import React from 'react';
import styles from './reservation-policy.module.scss';

export const ReservationPolicy = ({ isRoundTrip, isServiceActive, isOneWayAirportDestination }) => {
    // console.log()
    const renderArrivalInfo = () => (
      <>
        <b>For Arrival:</b> Our staff is located outside of the airport, TERMINAL 1 RIGHT HAND SIDE / TERMINAL 2 UMBRELLA # 3./ Member would like to have his name on a sign to recognize transportation.
        {isServiceActive && (<><br />
          <span className={styles.serviceText}> Member would like a stop of 30 min at store ( *SOCIO QUIERE PARADA DE 30 MINS EN SUPERMERCADO* )</span>
          </>
        )}
      </>
    );
    const renderDepartureInfo = () => (
      <>
        <b>For Departure:</b> Member will be picked up 3 hours before Flight departure time, Driver has up to 15 minutes courtesy time to wait in case it’s needed. Thank you for your understanding. Have a great trip and enjoy!
      </>
    );
    const renderCandellationPolicy = () => {
       return <>
            <h5>RESERVATION / CANCELLATION POLICY</h5>
            <ul>
            <li>All Reservations are non-transferable</li>
            <li>Reservations must be made at least 48hrs prior to transportation service date.</li>
            <li>Arrival modifications require a 48-hour prior notification.</li>
            <li>Departure modifications require a 24-hour prior notification.</li>
            <li>Any cancellation, including major causes, incurs a 10% retention fee for administrative costs.</li>
            <li>Cancellations must be made at least 48hrs prior to service date to avoid penalties.</li>
            <li>Cancellations within 48hrs incur a 50% retention of the total cost.</li>
            <li>No-shows incur a 100% retention of the total cost.</li>
            <li>Refunds depend on times, payment method, and defined penalties. Claims after 30 days or that don’t meet criteria are non-refundable.</li>
            </ul>
        </>
    }
  
    return (
      <div className="reservations-policies px-3">
        {isRoundTrip ? (
          <div>
            <p><b>FOR ROUND TRIP RESERVATION</b></p>
            <p>{renderArrivalInfo()}</p>
            <p>{renderDepartureInfo()}</p>
          </div>
        ) : (
          <div>
            <p><b>FOR ONE WAY RESERVATION</b></p>
            {
                !isOneWayAirportDestination ?
                <p>{renderArrivalInfo()}</p>
                :
                <p><b>For Departure: Airport SJD Destination:</b> {renderDepartureInfo()}</p>
            }
          </div>
        )}
        {renderCandellationPolicy()}
      </div>
    );
  };
  