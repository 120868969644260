import React, { useContext, useState, useEffect } from 'react';
import { Button, Tooltip } from 'reactstrap';
import { ReservationContext } from '../../context/reservation.context';
import styles from './get-stpp-super-market.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShop } from '@fortawesome/free-solid-svg-icons';

export const GetStopSuperMarket = ({ data, setTotalPayment, isServiceActive, setIsServiceActive, setIsStopatStore, totalPayment, args }) => {
  const { reservation } = useContext(ReservationContext);
  const { rate_stop_super_store } = reservation;
  // Estado para el Tooltip
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  // Estado local para el estatus del servicio activo
  const [serviceActiveStatus, setServiceActiveStatus] = useState(isServiceActive);

  // Estado para determinar si el botón debe estar deshabilitado
  const [disabledButton, setDisabledButton] = useState(true);

  useEffect(() => {
    setDisabledButton(!totalPayment || data.destination_location === 'Airport SJD');
  }, [totalPayment, data.destination_location]);

  const handleButtonClick = () => {
    if (!serviceActiveStatus) {
      setTotalPayment(parseFloat(totalPayment) + rate_stop_super_store); // Sumar el costo si se activa el servicio
      setIsStopatStore(true);
      setIsServiceActive(true); // Cambiar el estado del servicio a activo
      setServiceActiveStatus(true); // Estado local para este componente
    } else {
      setTotalPayment(parseFloat(totalPayment) - rate_stop_super_store); // Restar el costo si se desactiva el servicio
      setIsStopatStore(false);
      setIsServiceActive(false); // Cambiar el estado del servicio a inactivo
      setServiceActiveStatus(false); // Estado local para este componente
    }
  };

  return (
    <div>
      <h3>Extra Services:</h3>
      <div className={styles.container}>
        <div className={styles.icon_container}>
          <FontAwesomeIcon icon={faShop} />
        </div>
        <h6>Stop at the Supermarket or Grocery Store</h6>
        <h5 style={{ color: '#000000' }}>${rate_stop_super_store} USD</h5>
        <Button
          id="disableButton"
          color="primary"
          onClick={handleButtonClick}
          disabled={disabledButton}
        >
          {serviceActiveStatus ? 'Remove' : 'Add'}
        </Button>
        {disabledButton && (
          <Tooltip
            {...args}
            isOpen={tooltipOpen}
            target="disableButton"
            toggle={toggle}
          >
            Please select Pickup and Destination first. The destination must be a hotel.
          </Tooltip>
        )}
      </div>
    </div>
  );
};

GetStopSuperMarket.args = {
  autohide: true,
  flip: true,
};

GetStopSuperMarket.argTypes = {
  placement: {
    control: { type: 'select' },
    options: ['top', 'left', 'right', 'bottom'],
  },
};
